import React, { useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import 'styles/Merken.scss'

// Images

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BannerCustom } from 'components/Elements/Elements'
import BrandCard from 'components/Brands/BrandCard'
import Specialist from 'components/Specialist'

// Third Party
import { filter, indexOf } from 'lodash'
import { BannerWithLine } from '../components/Elements/Elements'

function Merken() {
  const {
    merken: {
      acf: {
        banner_1: { button_text: bannerButtonText1, text: bannerText1 },
        banner_2: { button_text: bannerButtonText2, text: bannerText2 },

        banner_afbeelding: {
          localFile: {
            childImageSharp: { fluid: bannerImage },
          },
        },
        specialist_banner_image_2: {
          localFile: {
            childImageSharp: { fluid: specialistBannerImage },
          },
        },
        specialist,
        relation: brandSpotlightList,
      },
      yoast_meta: yoast,
    },
    merken2: { edges: otherBrandsList },
  } = useStaticQuery(graphql`
    {
      merken: wordpressPage(wordpress_id: { eq: 34 }) {
        acf {
          banner_1 {
            button_text
            text
          }
          banner_2 {
            button_text
            text
          }

          specialist_banner_image_2 {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          specialist {
            title
            acf {
              number
              specialist_text
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 250) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          banner_afbeelding {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          relation {
            slug
            title
            soorten {
              name
              slug
            }
            acf {
              brand_description
              brand_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
      merken2: allWordpressWpMerk {
        edges {
          node {
            slug
            title
            soorten {
              name
              slug
            }
            acf {
              brand_description
              brand_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const mappedList = brandSpotlightList.map((o) => o.slug)
  const filteredOtherBrandsList = filter(
    otherBrandsList,
    (b) => indexOf(mappedList, b.node.slug) === -1
  )

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize))
    }
    return R
  }

  const [chunkSize, setChunkSize] = useState(3)

  const updateChunk = () => {
    setChunkSize(chunkSize + 3)
  }

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="merken">
        <h1 className="d-none">Kooloos Zon en Huis | Thuis in elk seizoen</h1>
        <BackgroundImage
          className="smaller-background-image"
          fluid={bannerImage}
        />
        <BannerCustom
          to="/showroomafspraak-maken"
          className="color-background-secondary"
          bannerText={bannerText1}
          bannerButtonText={bannerButtonText1}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="d-flex flex-wrap py-5 justify-content-center">
              <h2 className="font-size-xl m-0 text-center font-weight-xl w-100">
                Onze Merken
              </h2>
              <p className="font-size-sm font-weight-xl text-center color-text-contrast w-100">
                Ongeacht het product, bij kooloos werken we alleen met
                kwalitatieve merken.
              </p>
            </div>
          </div>
          <div className="row justify-content-evenly">
            {brandSpotlightList.map((brand) => (
              <BrandCard brand={brand} />
            ))}
          </div>
        </div>
        <BannerCustom
          to="/showroomafspraak-maken"
          className="color-background-main"
          bannerText={bannerText2}
          bannerButtonText={bannerButtonText2}
        />
        <div className="container pt-5">
          <div className="row justify-content-evenly">
            {chunk(filteredOtherBrandsList, chunkSize)[0].map((brand) => (
              <BrandCard brand={brand.node} />
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          {chunk(filteredOtherBrandsList, chunkSize).length > 1 && (
            <div
              tabIndex="0"
              role="button"
              className="color-background-contrast font-size-xm font-weight-xl color-text-light px-4 py-2 text-uppercase"
              onKeyDown={() => updateChunk()}
              onClick={() => updateChunk()}
            >
              Laad meer
            </div>
          )}
        </div>
        {/* <BannerWithLine className="color-background-main" left bannerText={bannerText3} /> */}
        {/* <div className="specialist-banner position-relative">
          <BackgroundImage fluid={specialistBannerImage} />
          <div className="specialist-container position-absolute container">
            <div className="position-relative">
              <Specialist specialist={specialist} horizontal className="position-absolute color-background-light" />
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

export default Merken
