import React from 'react'

// CSS
import './BrandCard.scss'

// Third Party
import _ from 'lodash'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import { ButtonLineRound } from '../Elements/Elements'

const BrandCard = ({ brand }) => (
  <div className="brand-card mb-5">
    <div className="row brand-card-image-container justify-content-center">
      <img alt="" src={brand.acf.brand_image.localFile.publicURL} />
    </div>
    <div className="row pt-3 flex-wrap justify-content-center">
      {_.map(brand.soorten, (soort) => (
        <div className="soort my-2 d-flex justify-content-center w-100">
          <ButtonLineRound to={`/producten/${soort.slug}/${soort.slug}`}>
            <p className="d-flex justify-content-center m-0">{soort.name}</p>
            <div className="button-line-round-image-container">
              <img alt="" src={orangeArrow} />
            </div>
          </ButtonLineRound>
        </div>
      ))}
    </div>
    <div className="brand-card-context pt-0 pt-lg-4 pb-4 px-4">
      <h2 className="font-size-m font-weight-xl color-text-contrast">
        {brand.title}
      </h2>
      <div
        className="font-size-sm text-justify"
        dangerouslySetInnerHTML={{ __html: brand.acf.brand_description }}
      />
    </div>
  </div>
)

export default BrandCard
